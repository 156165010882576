import React, { useCallback, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslations } from '@hooks'
import classnames from 'classnames'

import { AFormInput } from '@atoms/AFormInput'

export const MChatInput = ({
  handleOnSubmit = () => {},
  handleCancelCurrentThread = () => {},
  isInputDisabled = false,
  isNewMessagesLoading = false,
  isEnableToCancel = false,
  isModalOpen = false,
  className = '',
}) => {
  const { t } = useTranslations()
  const { control, reset, handleSubmit, setFocus } = useForm({
    defaultValues: {
      phrase: '',
    },
  })

  const setFocusOnInput = useCallback(() => {
    setFocus('phrase')
  }, [setFocus])

  const handleNewPhrase = useCallback(
    phrase => {
      if (!phrase) return

      handleOnSubmit(phrase)
      reset()
    },
    [handleOnSubmit, reset]
  )

  const onSubmit = useCallback(
    data => {
      handleNewPhrase(data.phrase)
    },
    [handleNewPhrase]
  )

  useEffect(() => {
    if (isModalOpen) {
      setFocusOnInput()
    }
  }, [isModalOpen, setFocusOnInput])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classnames('flex flex-row items-center', className)}
    >
      <div className="flex flex-row items-center w-full bg-white border rounded-full h-12">
        <div className="w-full ml-0.5 mr-0.5">
          <Controller
            control={control}
            name="phrase"
            render={({ field, fieldState }) => {
              return (
                <AFormInput
                  {...field}
                  id="phrase"
                  placeholder={
                    isNewMessagesLoading ? t('coach.label.loading') : t('coach.label.placeholder')
                  }
                  type="text"
                  disabled={isInputDisabled}
                  border={false}
                  className="!mt-0 !px-4 !rounded-full disabled:opacity-50"
                />
              )
            }}
          />
        </div>
      </div>
      <div className="ml-2 lg:ml-6">
        {isEnableToCancel ? (
          <button
            onClick={handleCancelCurrentThread}
            className="flex items-center justify-center h-10 w-10 rounded-full text-codGray bg-green disabled:opacity-50"
          >
            <svg viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path
                fillRule="evenodd"
                d="M4.5 7.5a3 3 0 013-3h9a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        ) : (
          <button
            type="submit"
            disabled={isInputDisabled}
            className="flex items-center justify-center h-10 w-10 rounded-full text-codGray bg-green disabled:opacity-50"
          >
            <svg
              className="w-5 h-5 transform rotate-90 -mr-px"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              ></path>
            </svg>
          </button>
        )}
      </div>
    </form>
  )
}

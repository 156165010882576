import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  getSanityImage,
  // useAuthor
} from '@edwin/react-sdk'

import ReactMarkdown from 'react-markdown'
import ProgressiveImage from 'react-progressive-graceful-image'
import { ACloseIcon } from '../atoms/ACloseIcon'
import { ATypewriter } from '../atoms/ATypewriter'

export const OMissionDetailsSidebar = ({
  title = '',
  imageUrl,
  imageMeta,
  steps,
  activeStep = null,
  translations,
  shouldDisplayCloseIcon = true,
  objectives,
  onClose = () => {},
}) => {
  // const { author } = useAuthor()

  // const textTranslations = {
  //   author: 'with ',
  //   steps: "What we'll cover",
  //   ...translations,
  // }

  return (
    <div className="hidden lg:block w-[300px] xl:w-[360px]">
      <div className="fixed top-0 w-[300px] xl:w-[360px] h-screen text-codGray bg-white shadow-md overflow-auto">
        {shouldDisplayCloseIcon && (
          <div className="relative">
            <div className="absolute top-2 left-2 z-40">
              <ACloseIcon iconName="chevronLeft" onClick={onClose} />
            </div>
          </div>
        )}
        {imageUrl && (
          <ProgressiveImage
            placeholder={getSanityImage(imageUrl, {
              width: 100,
              height: 100,
              crop: imageMeta?.crop,
              hotspot: imageMeta?.hotspot,
              quality: 20,
            })}
            src={getSanityImage(imageUrl, {
              width: 600,
              height: 600,
              crop: imageMeta?.crop,
              hotspot: imageMeta?.hotspot,
            })}
          >
            {src => <img className="w-full h-72 object-cover m-0 p-0" src={src} alt="" />}
          </ProgressiveImage>
        )}
        <div className={classnames('p-6', { 'pt-14': !imageUrl && shouldDisplayCloseIcon })}>
          {title && (
            <>
              <h2 className="text-3xl font-medium font-p22">{title}</h2>
              <div className="mt-2.5 w-16 h-1 bg-codGray" />
            </>
          )}
          <div className="mt-10 xl:pr-4">
            {steps.map((step, index) => (
              <div
                key={step}
                className={classnames(
                  'mb-4 pb-4 text-xl border-b border-[#C4C4C4]',
                  activeStep <= index ? '' : 'font-bold'
                )}
              >
                <p className="text-sm">{step}</p>
              </div>
            ))}
          </div>
          {objectives?.length ? (
            <div className="xl:pr-4">
              {objectives.map((objective, index) => (
                <div
                  key={objective?.id || index}
                  className="mb-4 pb-4 text-sm min-h-[37px] border-b border-[#C4C4C4] animate-fade-in opacity-0 truncate"
                >
                  <ATypewriter text={objective.label} delay={50} />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

OMissionDetailsSidebar.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
  imageUrl: PropTypes.string,
  imageMeta: PropTypes.object,
  onClose: PropTypes.func,
  authorName: PropTypes.string,
  activeStep: PropTypes.number,
}
